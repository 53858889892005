import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {useGetSinglePropertyQuery} from "../../../services/PropertiesApi"
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const ViewProperties = (props) => {
  const { Viewshow, handleClose,categoryId } = props;
  const { data, isLoading, isError } = useGetSinglePropertyQuery(categoryId);
  const [categoryDetail,setCategoryDetail] = useState('')
  

useEffect(()=>{
  setCategoryDetail(data?.data?.property)
  console.log('data---->',data?.data?.category);
},[data])
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose()
    
  };

  return (
    <Modal show={Viewshow} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">View Category</Modal.Title>
        </div>
      </Modal.Header>
      {/* {showLoader && <LoadingAction />} */}
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Property Name</label>
           <h3>{categoryDetail?.name}</h3>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary py-3 fw-bolder"
              style={buttonWidth}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ViewProperties