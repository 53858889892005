import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/API";

const getSupportTickets = createAsyncThunk(
  "getSupportTickets",
  async ({ role, page, limit }) => {
    const { data } = await api.get(
      `get-support-tickets?role=${role}&page=${page}&limit=${limit}`
    );
    return data;
  }
);

const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (obj) => {
    const { data } = await api.put("change-support-status", obj);
    return data;
  }
);

const getSupportDetails = createAsyncThunk("getSupportDetails", async (id) => {
  const { data } = await api.get(`get-support-details?id=${id}`);
  return data;
});

const supportReply = createAsyncThunk("supportReply", async (formdata) => {
  const { data } = await api.post("admin-support-reply", formdata);
  return data;
});

export {
  getSupportTickets,
  changeSupportStatus,
  getSupportDetails,
  supportReply,
};
