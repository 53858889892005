import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { api } from "../../../services/API";

const saveContent = createAsyncThunk("saveContent", async (content) => {
  const { data } = await api.post("/saveContent", content);
  if (data?.status) {
    toast.success(data?.message);
  }
  return data;
});
const getContent = createAsyncThunk("getContent", async (content) => {
  const { data } = await api.get(`getContent?content_type=${content}`);
  return data;
});

export { saveContent, getContent };
