import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";


export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreateCategoryPost: builder.mutation({
      query: (data) => ({
        url: "/create-category",
        method: "POST",
        body: data,
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      }),
    }),
    GetCategory: builder.query({
      query: () => ({
        url: "/get-all-category",
        method: "GET",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    GetSingleCategory: builder.query({
      query: (id) => ({
        url: `/get-category/${id}`,
        method: "GET",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    EditCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-category/${id}`,
        method: "PUT",
        body: data,
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    DeleteCategory: builder.mutation({
      query: (id) => ({
        url: `/delete-category/${id}`,
        method: "DELETE",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    getHelpAndSupport: builder.query({
      query: ({role}) => ({
        url: `/get-help-support?role=${role}`,
        method: "GET",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      }),
    }),
  }),
});

export const {
  useCreateCategoryPostMutation,
  useGetCategoryQuery,
  useGetSingleCategoryQuery,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useGetHelpAndSupportQuery
} = categoryApi;
