import axios from "axios";
import { api } from "./API";
//import { API_URL } from "../common/contants";
const API_URL = 'https://gib-api.alcax.com/api/v1/admin/'

const register = (username, email, password) => {
  return api.post("signup", {
    username,
    email,
    password,
  });
};

const login = async (email, password) => {
  return api
    .post("login", {
      email,
      password,
      role: "Admin",
    })
    .then((response) => {
      if (response?.data?.data?.user) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
      }
      return response.data;
    });

};

const logout = () => {
  localStorage.removeItem("user");
  sessionStorage.removeItem("token");
  return api.post("signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
