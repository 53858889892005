import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/API";

const getWithdrawals = createAsyncThunk(
  "getWithdrawals",
  async ({ page, limit }) => {
    const { data } = await api.get(
      `get-withdrawals?page=${page}&limit=${limit}`
    );
    return data;
  }
);

const changeWithdrawalStatus = createAsyncThunk("", async (obj) => {
  const { data } = await api.put("change-withdrawal-status", obj);
  return data;
});

export { getWithdrawals, changeWithdrawalStatus };
