import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {useGetSinglePropertyQuery,useEditPropertyMutation} from "../../../services/PropertiesApi"
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const EditProperties = (props) => {
  const { showEdit, handleClose,onPropertyEdit,categoryId } = props;
  const [editProperty] = useEditPropertyMutation();
 const { data, isLoading, isError } = useGetSinglePropertyQuery(categoryId);
  const [categoryName, setCategoryName] = useState("");
  

useEffect(()=>{
 setCategoryName(data?.data?.property?.name)
},[data,categoryId])

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

 

  const handleSubmit = (e) => {
    e.preventDefault();
   let data = {
    name:categoryName
   }
   editProperty({ id: categoryId, data: data })
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onPropertyEdit(res.status);
        handleClose(); 
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={showEdit} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Edit Category</Modal.Title>
        </div>
      </Modal.Header>
      {/* {showLoader && <LoadingAction />} */}
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Category Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={handleCategoryNameChange}
            />
          </div>
         
          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn btn-primary py-3 fw-bolder"
              style={buttonWidth}
            >
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProperties