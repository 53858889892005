import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useGetUserListQuery, useApprovedRefereeMutation, useDeleteUserMutation } from "../../services/UserApi"
import { FaCheck } from 'react-icons/fa';
import { toast } from "react-toastify";
import UserEdit from './userEdit';
import UserView from './userView';
import DeleteUser from './DeleteUser';

const Users = () => {

    const { data, refetch } = useGetUserListQuery();
    const [userList, setUserList] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('all')
    const [approvedReferee] = useApprovedRefereeMutation();
    const [userID, setUserId] = useState('')
    const [showEdit, setShowEdit] = useState(false)
    const [showView, setShowView] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteUser] = useDeleteUserMutation();

    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    const handleJobFilter = (e) => {
        setFilter(e.target.value)
        setOffset(0)
        setPage(0)
    }

    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = filter == "all" ? userList?.filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()) ||item?.email?.toLowerCase().includes(search?.toLowerCase())) : userList?.filter((item) => ((item?.name?.toLowerCase().includes(search?.toLowerCase()) || item?.email?.toLowerCase().includes(search?.toLowerCase())) && item.role == filter))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr?.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(userList?.length / itemsPerPage))
            let filtered = filter == "all" ? userList?.filter((item) => item?.name !== null) : userList?.filter((item) => (item?.name !== null) && item.role == filter)
            paginated = filtered?.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    useEffect(() => {
        paginationHandler()
    }, [userList, offset, search, filter]);
    useEffect(() => {
        let userFilter = data?.data?.users.filter((user) => user?.name !== null)
        let reverseObj=userFilter?.reverse() 
        setUserList(reverseObj)

        setPageCount(Math.ceil(reverseObj?.length / itemsPerPage))
    }, [data])
    const handleApproved = (id) => {
        approvedReferee(id)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                refetch()
                // handleClose();
                // setShowLoader(false);
            })
            .catch((err) => {
                //  setShowLoader(false);
                toast.error(err?.data?.message);
            });

    }
    const handleViewHandler = (id) => {
        setUserId(id)
        setShowView(true)
    }
    const handleEditHandler = (id) => {
        setShowEdit(true)
        setUserId(id)
    }

    const handleDeleteHandler = (id) => {
        setUserId(id)
        setShowDeleteModal(true)
    }

    const handleClose = () => {
        setShowEdit(false)
        setShowView(false)
        setShowDeleteModal()
    }
    const onPropertyEdit = (newData) => {
        if (newData) {
            refetch()
        }
    }

    const handleDeleteUser = () => {
        //  setShowLoader(true)
        deleteUser(userID)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                // setShowLoader(false)
                refetch();
            })
            .catch((err) => {
                // setShowLoader(false)
                toast.error(err?.data?.message);
            });
    };

    return (
        <div className="container">
            <div className="clearfix mb-3">
                <div className="float-start">
                    <header className="jumbotron">
                        <h3 className='mb-0'>User</h3>
                    </header>
                </div>
                {/* <div className="float-end">
                    <button type="button" className="btn btn-success float-end  d-flex align-items-center fw-bolder"
                    > <i className='bx bx-plus'></i>Add User</button>
                </div> */}
            </div>
            {showView && (<UserView showView={showView} handleClose={handleClose} userID={userID} />)}
            {showEdit && <UserEdit showEdit={showEdit} handleClose={handleClose} userID={userID} onPropertyEdit={onPropertyEdit} />}
            {showDeleteModal && <DeleteUser showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteUser} />}
            <div className="card mt-4">
                <div className="card-body">
                    <div className="clearfix mb-3">
                        <div className="float-start">
                            <div class="search-user"><label className="d-flex align-items-center">Search  <input type="search" class="search-input form-control ms-2" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                        </div>
                        <div className="float-end">
                            <div class="search-user"><label className="d-flex align-items-center text-nowrap">Filter
                                <select onChange={handleJobFilter} className="ms-2 form-control form-select">
                                    <option value="all">All</option>
                                    <option value="Client">Client</option>
                                    <option value="Referee">Referee</option>
                                    <option value="Leads">Leads</option>
                                </select>
                            </label></div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table class="table category-table w-100">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginated?.map((user, index) =>
                                    <tr key={index}>
                                        <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                                        <td>{user.name}</td>
                                        <td>
                                            {user.email}
                                            {/* sdjsgahdgsa */}
                                        </td>
                                        <td>{user.role}</td>
                                        <td>{user.status}
                                            {(user.status && user.role == 'Referee') ? <p style={{ color: "green" }}>Approved</p> : (((user.role == 'Client' && !user.status) || (user.role == 'Leads' && !user.status)) ? <p style={{ color: "orange" }}>Accept</p> : <p style={{ color: "red" }}>Pending</p>)}
                                        </td>
                                        <td className='d-flex align-items-center'>
                                            <button type="button" class="btn btn-outline-success d-flex align-items-center" onClick={() => handleViewHandler(user?._id)}> <i className='bx bx-show'></i> View</button>
                                            <button type="button" class="btn btn-outline-primary  mx-3 d-flex align-items-center"
                                                onClick={() => handleEditHandler(user?._id)}
                                            > <i className='bx bx-pencil'></i>  Edit</button>
                                            <button type="button" class="btn btn-outline-danger d-flex align-items-center" onClick={() => handleDeleteHandler(user?._id)} ><i className='bx bx-trash'></i> delete</button>
                                            {user.role == 'Referee' && <button class="btn btn-outline-success  mx-3 d-flex align-items-center" type="button" onClick={() => handleApproved(user?._id)}>
                                                <FaCheck /> Approve
                                            </button>}

                                        </td>

                                    </tr>
                                )}


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            {userList?.length > 10 &&
                <ReactPaginate
                    breakLabel="..."
                    breakClassName={'break'}
                    nextLabel="next >"
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                    forcePage={page}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"

                />
            }
        </div>

    )
}

export default Users