import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTransactionDetails } from "../redux/actions/transactionActions";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import { Helmet } from "react-helmet";

const TransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { transactionDetails } = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(getTransactionDetails(id));
  }, []);

  const detailsArr = [
    {
      label: "Plan Details",
      arr: [
        { label: "Name", value: transactionDetails?.plan_id?.plan_name },
        {
          label: "Description",
          value: transactionDetails?.plan_id?.plan_description,
        },
        { label: "Type", value: transactionDetails?.plan_id?.plan_type },
        { label: "Price", value: transactionDetails?.plan_id?.price },
      ],
    },
    {
      label: "Referee Details",
      arr: [
        {
          label: "Name",
          value: transactionDetails?.plan_creator_id?.name,
        },
        {
          label: "Email",
          value: transactionDetails?.plan_creator_id?.email,
        },
      ],
    },
    {
      label: "User Details",
      arr: [
        {
          label: "Name",
          value: transactionDetails?.user_id?.name,
        },
        {
          label: "Email",
          value: transactionDetails?.user_id?.email,
        },
      ],
    },
  ];

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Transaction Details</h3>
      </header>

      <Helmet>
        <title>Transaction Details</title>
      </Helmet>

      <div className="card">
        <div className="card-body trans-card">
          <button onClick={() => navigate(-1)} className="btn back-btn">
            <i class="bx bx-arrow-back"></i>Back
          </button>
          <div className="card-body trans-details-card">
            <div className="container">
              <div className="createdAt-div">
                <span className="">Created At:</span>
                <h6 className="">
                  {" "}
                  {moment(transactionDetails?.createdAt).format("DD/MM/YYYY")}
                </h6>
              </div>

              {detailsArr?.map((detail) => (
                <div className="row row-class">
                  <h4>{detail?.label}</h4>
                  <div className="details-div">
                    {detail?.arr?.map((detail) => {
                      return (
                        <InputGroup className="mb-3">
                          <InputGroup.Text className="">
                            {detail?.label}
                          </InputGroup.Text>
                          <Form.Control
                            as={
                              detail?.label === "Description"
                                ? "textarea"
                                : "input"
                            }
                            value={detail?.value}
                          />
                        </InputGroup>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
