import { createSlice } from "@reduxjs/toolkit";
import {
  changeWithdrawalStatus,
  getWithdrawals,
} from "../components/redux/actions/withdrawalActions";
import toast from "react-hot-toast";

const initialState = {
  withdrawals: null,
  pagination: null,
  //   transactionDetails: null,
};

const withdrawalSlice = createSlice({
  name: "withdrawalSlice",
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getWithdrawals.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.withdrawals = payload.data.data;
        state.pagination = payload.data.pagination;
      }
    });

    addCase(changeWithdrawalStatus.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.withdrawals = state.withdrawals?.map((withd) => {
          let newWith = { ...withd };
          if (newWith?.wallet_id?._id == payload?.data?.wallet_id?._id) {
            newWith.wallet_id = payload?.data?.wallet_id;
          }
          newWith = newWith?._id == payload?.data?._id ? payload.data : newWith;
          return newWith;
        });
        toast.success(payload?.message);
      }
    });
  },
});

export default withdrawalSlice.reducer;
