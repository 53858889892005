import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import UserService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));
export const getUser = createAsyncThunk(
  "users/getUser",
  async ( filterData,  thunkAPI) => {
    try {
      const data = await UserService.getUsers(filterData);
      return  { users:data.users,usersPagination:data.pagination };

    } catch (error) {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getUserById = createAsyncThunk(
  "users/getUserById",
  async ( id,  thunkAPI) => {
    try {
      const data = await UserService.getUserById(id);
      return  { user:data.user};

    } catch (error) {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserById = createAsyncThunk(
  "users/updateUserById",
  async ( user,  thunkAPI) => {
    try {
      const data = await UserService.updateUserById(user);
      return  { user:data.data.user};

    } catch (error) {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

)
const initialState = {
    users: [],
    user: {},
    pagination:[],
}


const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {

      return {

        users: action.payload.users,
        usersPagination:action.payload.usersPagination,
      };
    },
    [getUser.rejected]: (state, action) => {
      return {
        ...state,
      };
    },
    [getUserById.fulfilled]: (state, action) => {

      return {
        ...state,
        user: action.payload.user,

      };
    },
    [getUserById.rejected]: (state, action) => {
      return {
        ...state,
      };
    },
    [updateUserById.fulfilled]: (state, action) => {
      return {
          ...state,
          users: {
              ...state.users,
              users: state.users.map((item) => {
                return item._id === action.payload.user._id ? action.payload.user : item;
            })
          },
      };
    },
    [updateUserById.rejected]: (state, action) => {
      return {
        ...state,
      };
    },

  },
});

const { reducer } = userSlice;
export default reducer;