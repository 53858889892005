import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { getTransactions } from "../redux/actions/transactionActions";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Transactions = () => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { transactions, pagination } = useSelector(
    (state) => state.transactions
  );

  useEffect(() => {
    dispatch(getTransactions({ page, limit }));
  }, [page]);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Transactions</h3>
      </header>

      <Helmet>
        <title>Transactions</title>
      </Helmet>

      <div className="card">
        <div className="card-body support-card">
          <div className="card-body support-table">
            <div className="container">
              <div className="row">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Client</th>
                      <th scope="col">Referee</th>
                      {/* <th scope="col">Plan name</th> */}
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                      {/* <th scope="col">Type</th> */}
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {transactions?.map((transObj, i) => {
                      return (
                        <tr>
                          <th scope="row">{(page - 1) * limit + i + 1}</th>
                          <td>{transObj?.user_id?.name}</td>
                          <td>{transObj?.plan_creator_id?.name}</td>
                          <td>{transObj?.amount}</td>
                          <td>
                            {moment(transObj?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          {/* <td>
                            <button className="btn credit-button disabled">
                              {transObj?.type}
                            </button>
                          </td> */}
                          <td>
                            <button
                              onClick={() =>
                                navigate(
                                  `/transaction-details/${transObj?._id}`
                                )
                              }
                              className="btn btn-primary"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination-div">
                {pagination && pagination?.totalPages > 1 && (
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={(e) => setPage(e.selected + 1)}
                    pageCount={pagination?.totalPages}
                    previousLabel="Prev"
                    renderOnZeroPageCount={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
