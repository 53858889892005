const modalData = {
  changeSupportStatus: {
    title: "Change Support Status",
    body: "Are you sure want to change this support's status?",
  },
  changeWithdrawalStatus: {
    title: "Change Withdrawal Status",
    body: "Are you sure want to change this withdrawal's request status?",
  },
};

export default modalData;
