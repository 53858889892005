import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";


const DeleteCategory = (props) => {
  const { showDeleteModal, handleClose,handleDelete } = props;
  const confirmDelete = () => {
    handleDelete();
    handleClose();
  };
  return (
    <Modal show={showDeleteModal} onHide={handleClose} centered>
      <Modal.Header closeButton={false} className="border-0 mt-3 pt-0 pb-2">
        {/* <div className="d-flex justify-content-center w-100">
          sfdksjaf
        </div> */}
      </Modal.Header>
      <Modal.Body className="text-center px-md-5  pb-0">
        <h4 className="text-red-sure">
          Are you sure you want to delete this category?
        </h4>
      </Modal.Body>
      <Modal.Footer className="d-flex border-0 justify-content-center  px-md-5 w-100 mx-auto mb-3 pb-0">
        <button
          type="submit"
          onClick={confirmDelete}
          className="btn w-100  btn-danger py-2 text-white"
        >
          Yes
        </button>
        <Button
          onClick={handleClose}
          className="bg-none px-md-4"
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



export default DeleteCategory