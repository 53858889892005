import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import userReducer from "./slices/user";
import staticContentReducer from "./slices/contentSlice";
import supportSlice from "./slices/supportSlice";
import transactionSlice from "./slices/transactionSlice";
import withdrawalSlice from "./slices/withdrawalSlice";
import { categoryApi } from "./services/CategoryApi";
import { propertyApi } from "./services/PropertiesApi";
import { userApi } from "./services/UserApi";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  userReducer: userReducer,
  staticContent: staticContentReducer,
  support: supportSlice,
  transactions: transactionSlice,
  withdrawal: withdrawalSlice,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [propertyApi.reducerPath]: propertyApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      categoryApi.middleware,
      propertyApi.middleware,
      userApi.middleware
    ),
  devTools: true,
});
