import { createSlice } from "@reduxjs/toolkit";
import {
  changeSupportStatus,
  getSupportDetails,
  getSupportTickets,
  supportReply,
} from "../components/redux/actions/supportActions";
import toast from "react-hot-toast";

const initialState = {
  role: null,
  data: null,
  pagination: null,
  supportDetails: null,
};

const supportSlice = createSlice({
  name: "supportSlice",
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getSupportTickets.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.role = payload.data.role;
        state.data = payload.data.data;
        state.pagination = payload.data.pagination;
      }
    });
    addCase(changeSupportStatus.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.data = state.data?.map((supportObj) =>
          supportObj?._id == payload?.data?._id ? payload?.data : supportObj
        );

        toast.success(payload?.message);
      }
    });
    addCase(getSupportDetails.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.supportDetails = payload?.data;
        state.supportChat = payload?.data?.chat;
      }
    });

    addCase(supportReply.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.supportDetails = payload?.data;
        state.supportChat = payload?.data?.chat;
        state.data = state.data?.map((supportObj) =>
          supportObj?._id == payload?.data?._id ? payload?.data : supportObj
        );
      }
    });
  },
});

export default supportSlice.reducer;
