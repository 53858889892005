import React, { useEffect } from "react";
import Header from "./partials/header";
import SideBar from "./partials/sidebar";
import Footer from "./partials/footer";

import "../../assets/plugins/vectormap/jquery-jvectormap-2.0.2.css";
import "../../assets/plugins/simplebar/css/simplebar.css";
import "../../assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
import "../../assets/plugins/metismenu/css/metisMenu.min.css";
import "../../assets/css/pace.min.css";
// import '../../assets/js/pace.min.js';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap-extended.css";
// import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap';
import "../../assets/css/app.css";
import "../../assets/css/icons.css";
import "../../assets/css/dark-theme.css";
import "../../assets/css/semi-dark.css";
import "../../assets/css/header-colors.css";
import { Outlet } from "react-router-dom";
// import '../../assets/js/bootstrap.bundle.min.js';
// import '../../assets/js/jquery.min.js';
// import '../../assets/plugins/simplebar/js/simplebar.min.js';
// import '../../assets/plugins/simplebar/js/simplebar.min.js';
// import '../../assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js';
// import '../../assets/plugins/vectormap/jquery-jvectormap-2.0.2.min.js';
// import '../../assets/plugins/vectormap/jquery-jvectormap-world-mill-en.js';
// import '../../assets/plugins/chartjs/js/Chart.min.js';
// import '../../assets/plugins/chartjs/js/Chart.extension.js';
// import '../../assets/js/index.js';
// import '../../assets/js/app.js';

const DashBoardLayout = () => {
  return (
    <body>
      <div className="wrapper">
        <SideBar />
        <Header />
        <div className="page-wrapper">
          <div className="page-content">
            <Outlet />
          </div>
        </div>

        <Footer />
      </div>
    </body>
  );
};
// const DashBoardLayout = ({ children }) => {
//   return (
//     <body>
//       <div className="wrapper">
//         <SideBar />
//         <Header />
//         <div className="page-wrapper">
//           <div className="page-content">{children}</div>
//         </div>

//         <Footer />
//       </div>
//     </body>
//   );
// };

export default DashBoardLayout;
