import axios from "axios";
import { api } from "./API";

//import { API_URL } from "../common/contants";
const API_URL = 'https://gib-api.alcax.com/api/v1/admin/'

const getUsers = (filterdata) => {
  return api
    .get("users", {
      params: filterdata,
    })
    .then((response) => {
      return response.data.data;
    });
};

const getUserById = (id) => {
  return api.get(`users/${id}`, {}).then((response) => {
    return response.data.data;
  });
};
const updateUserById = (data) =>
{
  return axios
  .post(API_URL + `users/${data._id}/update`, {
       user: data
  })
  .then((response) => {
    return response.data;
  });
}
const UserService = {
    getUsers,
    getUserById,
    updateUserById
}

export default UserService;
