import React, { useEffect } from "react";
import "../../assets/plugins/simplebar/css/simplebar.css";
import "../../assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
import "../../assets/plugins/metismenu/css/metisMenu.min.css";
import "../../assets/css/pace.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap-extended.css";
// import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap';
import "../../assets/css/app.css";
import "../../assets/css/icons.css";
import { Outlet } from "react-router-dom";
// import '../../assets/js/bootstrap.bundle.min.js';
// import $ from 'jquery'; // Import jQuery if not already imported in your project

const AuthLayout = () => {
  // useEffect(() => {
    // $(document).ready(() => {
    //   $("#show_hide_password a").on('click', (event) => {
    //     event.preventDefault();
    //     if ($('#show_hide_password input').attr("type") === "text") {
    //       $('#show_hide_password input').attr('type', 'password');
    //       $('#show_hide_password i').addClass("bx-hide");
    //       $('#show_hide_password i').removeClass("bx-show");
    //     } else if ($('#show_hide_password input').attr("type") === "password") {
    //       $('#show_hide_password input').attr('type', 'text');
    //       $('#show_hide_password i').removeClass("bx-hide");
    //       $('#show_hide_password i').addClass("bx-show");
    //     }
    //   });
    // });
  // }, []);

  return (
    <div className="bg-login">
      <Outlet />
    </div>
  );
};

export default AuthLayout;
