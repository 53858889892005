import React, { useEffect, useState } from "react";
import "./Custom.css"
import AddCategory from "./addCategory/AddCategory";
import ViewCategory from "./viewCategory/ViewCategory";
import DeleteCategory from "./deleteCategory/DeleteCategory";
import EditCategory from "./editCategory/EditCategory";
import { useGetCategoryQuery, useDeleteCategoryMutation } from "../../services/CategoryApi";
import { toast } from "react-toastify";

const Category = () => {
  const { data: retrieveCategory, refetch, isLoading } = useGetCategoryQuery();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [show, setShow] = useState(false);
  const [Viewshow, setViewShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categories, setCategories] = useState([""]);
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    setCategories(retrieveCategory?.data?.category);
  }, [retrieveCategory]);
  const AddCategoryHandler = () => {
    setShow(true);
  };
  const handleAddCategory = (newCategory) => {
    if (newCategory.user.status == 1) {
      refetch()
    }
    //setCategories([...categories, newCategory]);
  };
  const handleEditCategory = (newsaCategory) => {
    if (newsaCategory) {
      refetch()
    }
  }
  const editShowHandler = (e, id) => {
    e.preventDefault();
    setShowEdit(true);
    setCategoryId(id);
  };
  const deleteShowHandler = (e, id) => {
    e.preventDefault();
    setShowDeleteModal(true);
    setCategoryId(id);
  };
  const viewShowHandler = (e, id) => {
    e.preventDefault();
    setViewShow(true)
    setCategoryId(id);
  };
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteModal(false);
    setViewShow(false)
  };
  const handleDeleteCategory = () => {
    //  setShowLoader(true)
    deleteCategory(categoryId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        // setShowLoader(false)
        setCategories(
          categories.filter((category) => category.id !== categoryId)
        );
        refetch();
      })
      .catch((err) => {
        // setShowLoader(false)
        toast.error(err?.data?.message);
      });
  };


  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Categories</h3>
        <button type="button" className="btn btn-success float-end my-3 d-flex align-items-center fw-bolder"
          onClick={AddCategoryHandler}> <i className='bx bx-plus'></i>Add Category</button>
      </header>
      <AddCategory
        show={show}
        handleClose={handleClose}
        onCategoryAdded={handleAddCategory}
      />
      {Viewshow && (<ViewCategory Viewshow={Viewshow} handleClose={handleClose} categoryId={categoryId} />)}
      {showDeleteModal && (<DeleteCategory showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteCategory} />)}
      {showEdit && (<EditCategory showEdit={showEdit} handleClose={handleClose} onCategoryEdit={handleEditCategory} categoryId={categoryId} />)}
      <div className="card">
        <div className="table-responsive">
          <table class="table category-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">image</th>
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody>
              {categories?.map((category, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{category.name}</td>
                  <td>
                    <img
                      src="https://picsum.photos/100/100"
                      alt="image"
                      className="rounded-circle action-image"
                      height='35'
                      width='35'

                    />
                    {/* sdjsgahdgsa */}
                  </td>
                  <td className='d-flex align-items-center'>
                    <button type="button" class="btn btn-outline-success d-flex align-items-center" onClick={(e) => viewShowHandler(e, category?._id)}> <i className='bx bx-show'></i> View</button>
                    <button type="button" class="btn btn-outline-primary  mx-3 d-flex align-items-center" onClick={(e) =>
                      editShowHandler(e, category?._id)
                    }> <i className='bx bx-pencil'></i>  Edit</button>
                    <button type="button" class="btn btn-outline-danger d-flex align-items-center" onClick={(e) =>
                      deleteShowHandler(e, category?._id)
                    }><i className='bx bx-trash'></i> delete</button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Category