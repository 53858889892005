import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRouter = () => {
  // const token = sessionStorage.getItem("token");

  console.log(sessionStorage.getItem("token"));

  return <>{sessionStorage.getItem("token") ? <Outlet /> : <Navigate to="/" />}</>;
};

export default PrivateRouter;
