import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { getTransactions } from "../redux/actions/transactionActions";
import { useNavigate } from "react-router-dom";
import { getWithdrawals } from "../redux/actions/withdrawalActions";
import { Dropdown, DropdownButton } from "react-bootstrap";
import CommonModal from "../common/CommonModal";
import { Helmet } from "react-helmet";

const Withdrawals = () => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState();
  const { withdrawals, pagination } = useSelector((state) => state.withdrawal);

  const statusObj = {
    Pending: { label: "Pending", variant: "primary" },
    Declined: { label: "Declined", variant: "danger" },
    Paid: { label: "Paid", variant: "success" },
  };

  useEffect(() => {
    dispatch(getWithdrawals({ page, limit }));
  }, [page]);

  const handleChangeStatus = (e, id) => {
    setShow(true);
    setModalData({ id, status: e });
  };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Withdrawals</h3>
      </header>

      <Helmet>
        <title>Withdrawals</title>
      </Helmet>

      {modalData && (
        <CommonModal
          show={show}
          setShow={setShow}
          type="changeWithdrawalStatus"
          data={modalData}
        />
      )}

      <div className="card">
        <div className="card-body support-card">
          <div className="card-body support-table">
            <div className="container">
              <div className="row">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Referee</th>
                      <th scope="col">Wallet Balance</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {withdrawals?.map((withd, i) => {
                      return (
                        <tr>
                          <th scope="row">{(page - 1) * limit + i + 1}</th>
                          <td>{withd?.plan_creator_id?.name}</td>
                          <td>{withd?.wallet_id?.amount}</td>
                          <td>{withd?.amount}</td>
                          <td>
                            {moment(withd?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={withd?.status}
                                variant={statusObj[withd?.status]?.variant}
                                onSelect={(e) =>
                                  handleChangeStatus(e, withd?._id)
                                }
                              >
                                {Object.keys(statusObj)
                                  ?.filter((key) => key !== withd?.status)
                                  ?.map((key) => {
                                    return (
                                      <Dropdown.Item eventKey={key}>
                                        {statusObj[key]?.label}
                                      </Dropdown.Item>
                                    );
                                  })}
                              </DropdownButton>
                            </td>
                          </td>
                          {/* <td>
                            <button
                              onClick={() =>
                                navigate(
                                  `/transaction-details/${transObj?._id}`
                                )
                              }
                              className="btn btn-primary"
                            >
                              View Details
                            </button> 
                          </td>*/}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination-div">
                {pagination && pagination?.totalPages > 1 && (
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={(e) => setPage(e.selected + 1)}
                    pageCount={pagination?.totalPages}
                    previousLabel="Prev"
                    renderOnZeroPageCount={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawals;
