import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useCreatePropertyPostMutation } from "../../../services/PropertiesApi";
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const AddProperties = (props) => {
  const { show, handleClose,onCategoryAdded } = props;
  const [addProperties] = useCreatePropertyPostMutation();
  const [categoryName, setCategoryName] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };
 
  const handleSubmit = (e) => {
    let data = {
        name:categoryName
    }
    e.preventDefault();
    addProperties(data)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onCategoryAdded(res.data);
        handleClose();
        setCategoryName('')
       
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Add Property</Modal.Title>
        </div>
      </Modal.Header>
      {/* {showLoader && <LoadingAction />} */}
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Property Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={handleCategoryNameChange}
            />
          </div>
          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn btn-primary py-3 fw-bolder"
              style={buttonWidth}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddProperties