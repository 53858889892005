import React from "react";

const Footer = () => {
    return (
        <React.Fragment>
        <footer className="page-footer">
			<p className="mb-0">in footer Copyright © 2024. All right reserved.</p>
		</footer>
        </React.Fragment>
    )
}

export default Footer