import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Login from "./app/components/Auth/login";
import AuthLayout from "./app/components/layouts/auth";
import Home from "./app/components/Dashboard/Home";
import DashBoardLayout from "./app/components/layouts/dashboard";
import Users from "./app/components/Users/users";
import UserView from "./app/components/Users/userView";
import EditUser from "./app/components/Users/userEdit";
// import Register from "./app/components/Register";
// import Home from "./app/components/Home";
// import Profile from "./app/components/Profile";
// import BoardUser from "./app/components/BoardUser";
// import BoardModerator from "./app/components/BoardModerator";
// import BoardAdmin from "./app/components/BoardAdmin";

import { logout } from "./app/slices/auth";
import StaticContent from "./app/components/StaticContent/StaticContent";
import Support from "./app/components/Support";
import Transactions from "./app/components/transactions/Transactions";
import TransactionDetails from "./app/components/transactions/TransactionDetails";
import Withdrawals from "./app/components/withdrawals/Withdrawals";
import AppRoutes from "./app/routes/AppRoutes";

const App = () => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);

  // const { user: currentUser } = useSelector((state) => state.auth);

  // const dispatch = useDispatch();

  // const logOut = useCallback(() => {
  //   dispatch(logout());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (currentUser) {
  //     setShowModeratorBoard(currentUser.role.includes("ROLE_MODERATOR"));
  //     setShowAdminBoard(currentUser.role === "Admin");
  //   } else {
  //     setShowModeratorBoard(false);
  //     setShowAdminBoard(false);
  //   }
  // }, [currentUser]);

  return (

    <AppRoutes />
  

    // <Router>
    //   <div>
    //     <nav className="navbar navbar-expand navbar-dark bg-dark">
    //       <Link to={"/"} className="navbar-brand">
    //       Test
    //       </Link>
    //       <div className="navbar-nav mr-auto">
    //         <li className="nav-item">
    //           <Link to={"/home"} className="nav-link">
    //             Home
    //           </Link>
    //         </li>

    //         {showModeratorBoard && (
    //           <li className="nav-item">
    //             <Link to={"/mod"} className="nav-link">
    //               Moderator Board
    //             </Link>
    //           </li>
    //         )}

    //         {showAdminBoard && (
    //           <li className="nav-item">
    //             <Link to={"/admin"} className="nav-link">
    //               Admin Board
    //             </Link>
    //           </li>
    //         )}

    //         {currentUser && (
    //           <li className="nav-item">
    //             <Link to={"/user"} className="nav-link">
    //               User
    //             </Link>
    //           </li>
    //         )}
    //       </div>

    //       {currentUser ? (
    //         <div className="navbar-nav ml-auto">
    //           <li className="nav-item">
    //             <Link to={"/profile"} className="nav-link">
    //               {currentUser.username}
    //             </Link>
    //           </li>
    //           <li className="nav-item">
    //             <a href="/login" className="nav-link" onClick={logOut}>
    //               LogOut
    //             </a>
    //           </li>
    //         </div>
    //       ) : (
    //         <div className="navbar-nav ml-auto">
    //           <li className="nav-item">
    //             <Link to={"/login"} className="nav-link">
    //               Login
    //             </Link>
    //           </li>

    //           <li className="nav-item">
    //             <Link to={"/register"} className="nav-link">
    //               Sign Up
    //             </Link>
    //           </li>
    //         </div>
    //       )}
    //     </nav>

    //     <div className="container mt-3">
    //       <Routes>
    //         <Route path="/" element={<Home />} />
    //         <Route path="/home" element={<Home />} />
    //         <Route path="/login" element={<Login />} />
    //         <Route path="/register" element={<Register />} />
    //         <Route path="/profile" element={<Profile />} />
    //         <Route path="/user" element={<BoardUser />} />
    //         <Route path="/mod" element={<BoardModerator />} />
    //         <Route path="/admin" element={<BoardAdmin />} />
    //       </Routes>
    //     </div>
    //   </div>
    // </Router>
  );
};

export default App;
