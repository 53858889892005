import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/API";

const getTransactions = createAsyncThunk(
  "getTransactions",
  async ({ page, limit }) => {
    const { data } = await api.get(
      `get-transactions?page=${page}&limit=${limit}`
    );
    return data;
  }
);

const getTransactionDetails = createAsyncThunk(
  "get-transaction-details",
  async (id) => {
    const { data } = await api.get(`get-transaction-details?id=${id}`);
    return data;
  }
);

export { getTransactions , getTransactionDetails };
