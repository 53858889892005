import { createSlice } from "@reduxjs/toolkit";
import { getContent } from "../components/redux/actions/staticContentActions";

const initialState = {
  content_type: null,
  content: null,
};

const staticContentSlice = createSlice({
  name: "staticContentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContent.fulfilled, (state, { payload }) => {
      if (payload?.status === true) {
        state.content = payload.data.content;
        state.content_type = payload.data.content_type;
      }
    });
  },
});

export default staticContentSlice.reducer;