import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getTransactionDetails, getTransactions } from "../components/redux/actions/transactionActions";

const initialState = {
  transactions: null,
  pagination: null,
  transactionDetails: null,
};

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getTransactions.fulfilled, (state, { payload }) => {
      if (payload?.status) {
        state.transactions = payload.data.data;
        state.pagination = payload.data.pagination;
      }
    });

    addCase(getTransactionDetails.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          state.transactionDetails = payload.data;
        }
      });
  },
});

export default transactionSlice.reducer;
