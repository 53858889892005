import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";


export const propertyApi = createApi({
  reducerPath: "propertyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreatePropertyPost: builder.mutation({
      query: (data) => ({
        url: "/create-property",
        method: "POST",
        body: data,
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      }),
    }),
    GetProperty: builder.query({
      query: () => ({
        url: "/get-all-property",
        method: "GET",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    GetSingleProperty: builder.query({
      query: (id) => ({
        url: `/get-property/${id}`,
        method: "GET",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    EditProperty: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-property/${id}`,
        method: "PUT",
        body: data,
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
    DeleteProperty: builder.mutation({
      query: (id) => ({
        url: `/delete-property/${id}`,
        method: "DELETE",
        // headers: {
        //   authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        // },
      }),
    }),
  }),
});

export const {
  useCreatePropertyPostMutation,
  useGetPropertyQuery,
  useGetSinglePropertyQuery,
  useEditPropertyMutation,
  useDeletePropertyMutation,
} = propertyApi;
