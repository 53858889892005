import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupportDetails,
  supportReply,
} from "../redux/actions/supportActions";

export default function SupportModal({ show, setShow, supportId }) {
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const { supportDetails, supportChat } = useSelector((state) => state.support);

  useEffect(() => {
    if (supportId) {
      dispatch(getSupportDetails(supportId));
    }
  }, [supportId]);

  const handleSubmit = (values) => {
    const formdata = new FormData();
    formdata.append("message", values.message);
    formdata.append("id", supportId);
    dispatch(supportReply(formdata));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container">
            <div class="row clearfix">
              <div class="col-lg-12">
                <div class="chat-app">
                  <div class="chat">
                    <div class="chat-history">
                      <ul class="m-b-0">
                        <li class="clearfix">
                          <div class="message my-message">
                            {supportDetails?.description}
                            {/* <div class="message-data">
                                    <span class="message-data-time">
                                      10:12 AM, Today
                                    </span>
                                  </div> */}
                          </div>
                        </li>
                        {supportChat?.map((chatObj) => {
                          if (chatObj?.sent_by === "Admin") {
                            return (
                              <li class="clearfix">
                                {/*  <div class="message-data float-right">
                                  <span class="message-data-time">
                                    10:10 AM, Today
                                  </span>
                                  <img
                              src="https://bootdey.com/img/Content/avatar/avatar7.png"
                              alt="avatar"
                            /> 
                                </div> */}
                                <div class="message other-message float-right">
                                  {chatObj?.message}
                                  {/* <div class="message-data float-right">
                                    <span class="message-data-time">
                                      10:10 AM, Today
                                    </span>
                                  </div> */}
                                </div>
                              </li>
                            );
                          } else {
                            return (
                              <li class="clearfix">
                                <div class="message my-message">
                                  {chatObj?.message}
                                  {/* <div class="message-data">
                                    <span class="message-data-time">
                                      10:12 AM, Today
                                    </span>
                                  </div> */}
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{ message: "" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              values?.message !== "" && handleSubmit(values);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div class="input-group mb-3">
                  <Field
                    placeholder="Type here..."
                    className="form-control"
                    type="text"
                    name="message"
                  />
                  {/* <ErrorMessage name="email" component="div" /> */}
                  <span class="input-group-text" id="basic-addon2">
                    <button
                      className="btn send-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <i class="bx bxs-send"></i>
                    </button>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
