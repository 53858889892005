import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {useGetSingleCategoryQuery,useEditCategoryMutation} from "../../../services/CategoryApi"
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const EditCategory = (props) => {
  const { showEdit, handleClose,onCategoryEdit,categoryId } = props;
  const [editCategory] = useEditCategoryMutation();
 const { data, isLoading, isError } = useGetSingleCategoryQuery(categoryId);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

useEffect(()=>{
 setCategoryName(data?.data?.category?.name)
},[data,categoryId])

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("image", image);
    editCategory({ id: categoryId, data: formData })
      .unwrap()
      .then((res) => {
        console.log('res---->',res);
        toast.success(res?.message);
        onCategoryEdit(res.status);
        handleClose(); 
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={showEdit} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Edit Category</Modal.Title>
        </div>
      </Modal.Header>
      {/* {showLoader && <LoadingAction />} */}
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Category Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={handleCategoryNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn btn-primary py-3 fw-bolder"
              style={buttonWidth}
            >
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};


export default EditCategory